import React, { useState } from "react";
import Layout from "../components/Layout/layout";
import Client from "../components/client";
import { Container, Row } from "react-bootstrap";
import Solutions from "../components/solutions";
import Atribs from "../components/atribs";
import About from "../components/about";
import Careers from "../components/careers";
import Contact from "../components/contact";
import AboutUs from "../components/aboutus";
import Partners from "../components/partners";
import ForteMobile from "../components/forteMobile";
import Automation from "../components/automation";
import AboutDetails from "../components/aboutDetails";
import ForteFinal from "../components/ForteFinal";
import CorouselContainer from "../components/corouselContainer";
import JourneyMap from "../components/journeyMap";
import OtherPartners from "../components/otherPartners";
 //test
export default function Home() {
  const [isClientRead, setIsClientRead] = useState(null);
  const [isabout, setAbout] = useState(false);
  const HideContent = (data) => {
    setIsClientRead(null);
    setAbout(data);
  };
  return (
    <>
      {/* <div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
      </div> */}
      <Layout HideContent={HideContent} isabout={isabout}>
        {isabout === true ? (
          <AboutDetails HideContent={HideContent} />
        ) : (
          <>
            {" "}
            <Row className="Client-container" id="home">
              {/* <Client setIsClientRead={setIsClientRead} /> */}
              <CorouselContainer setIsClientRead={setIsClientRead} />
            </Row>
            {isClientRead !== null ? (
              <Automation isClientRead={isClientRead} />
            ) : (
              <>
                <h3 className="title">About Us</h3>
                <JourneyMap />
                <h3 className="title">Our&nbsp;Forte</h3>
                <div className="forte-container" id="forte">
                  <div className="forte-mb d-md-block d-sm-none">
                    {/* <Forte /> */}
                    <ForteFinal />
                  </div>
                  <div className="d-md-none">
                    <ForteMobile />
                  </div>
                </div>
                <div className="solution-container" id="solution">
                  <h3 className="title">SOLUTIONS</h3>

                  <div className="solution-content">
                    <p>
                      We are experts in Digital Transformation, Business
                      Consulting, Application Services, Cloud Technologies and
                      Kiosk Solutions
                    </p>
                  </div>
                  <Solutions />
                </div>
                <div
                  fluid
                  className="partial-container "
                  // style={{backgroundColor:'#5f8694'}}
                  id="partial"
                >
                  <div className="" id="partners">
                    <Partners />
                  </div>
                </div>
                <div fluid className="partial-container-others" id="partial">
                  <div className="" id="other-partners">
                    <OtherPartners />
                  </div>
                </div>

                <Container className="atribs-container" id="atribs">
                  <h3 className="title">ATRIBS</h3>
                  <Atribs />
                </Container>
                {/* ---------- modified on 20/01/2025 ---------- */}
                <Container
                  className="about-container  d-md-block d-sm-none"
                  id="about"
                >
                  <About setAbout={setAbout} />
                </Container>
                <Container className="aboutus-container 	d-md-none" id="about">
                  <AboutUs setAbout={setAbout} />
                </Container>
                <Container className="Career-container" id="career">
                  <Row className="Career-header">
                    <p
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "600",
                        fontSize: "48px",
                      }}
                    >
                      CAREERS
                    </p>
                  </Row>
                  <Row md={12} sm={12} lg={12}>
                    <Careers />
                  </Row>
                </Container>
                {/* ---------- ------------- */}
                <div
                  md={12}
                  lg={6}
                  sm={4}
                  className="contact-container container"
                >
                  <Row>
                    <Contact />
                  </Row>
                </div>
              </>
            )}
          </>
        )}
      </Layout>
    </>
  );
}
