import systemIntegration from "../assets/systemIntegration.png";
import digital from "../assets/digital.png";
import appLogo from "../assets/appLogo.png";
import professional from "../assets/professional1.png";
import leverage from "../assets/leverage.svg";
import connected from "../assets/connected.svg";
import about from "../assets/about.png";
import mission from "../assets/mission.png";
import team from "../assets/team.png";
import insideimg from "../assets/insidecircle.svg";
import DataManagement from "../assets/DataManagement.svg";
import Agumented from "../assets/agumented.svg";
import Unified from "../assets/unifiedDigital.svg";
import TradeFinance from "../assets/TradeFinance.png";
import WhatsappBot from "../assets/readWhatsappBot.png";
import ReadSoti from "../assets/readSoti.png";
import MicroFinance from "../assets/Microfinance.png";
import Itops from "../assets/itops-itsm.png";
import IndustialMAintenace from "../assets/industrialMaintenace.svg";
import Cattle from "../assets/cattleManegementMore.png";
import Blackchain from "../assets/Blackchain.svg";
import AllBased from "../assets/Allbased.png";
import AgriLending from "../assets/agriLending.png";
import Uni from "../assets/Uni.svg";
import Industrial from "../assets/Industrial.svg";
import Micro from "../assets/MicroNew.png";
import Augmented from "../assets/Augmented.svg";
import Trade from "../assets/Trade.png";
import Agri from "../assets/Agri.png";
import blockchain from "../assets/blockchain.svg";
import cyber from "../assets/cyber.png";
import cattle from "../assets/cattleManegementsize.png";
import bot from "../assets/WhatsappBot.png";
import maintenance from "../assets/maintenance.svg";
import ReadMaintainace from "../assets/ReadMaintenance.svg";
import ReadEnergy from "../assets/ReadEnergy.png";
import ReadHRMS from "../assets/readHRMS.png";
import ReadHRMS2 from "../assets/readHRMS2.png";

import ReadLocode from "../assets/ReadLocode.svg";
import HRMS from "../assets/HRMS.png";
import HRMS2 from "../assets/HRMS2.png";

import Locode from "../assets/Locode.svg";
import CloudAutomation from "../assets/CloudAutomation.png";
import EnergyControl from "../assets/EnergyControl.png";
import itops from "../assets/itops.png";
import AWS from "../assets/soti.png";
import ACLogo from "../assets/ACLogo.svg";
import TCLogo from "../assets/TCLogo.svg";
import RCLogo from "../assets/RCLogo.svg";
import ICLogo from "../assets/ICLogo.svg";
import BCLogo from "../assets/BCLogo.svg";
import SCLogo from "../assets/SCLogo.svg";
import Aarc from "../assets/partnersLogo/Aarc-new.svg";
import Aisensy from "../assets/partnersLogo/AISensy-new.svg";
import CMA from "../assets/partnersLogo/CMAconsult-new.svg";
import Crud from "../assets/partnersLogo/Crud-new.svg";
import Darktarace from "../assets/partnersLogo/Darktract-new.svg";
import Digipay from "../assets/partnersLogo/DigiPay-guru-new.svg";
import DigitalXC from "../assets/partnersLogo/DigitalXC-new.svg";
import FIIX from "../assets/partnersLogo/Fiix-new.svg";
import GOODMEETINGS from "../assets/partnersLogo/GoodMeeting-new.svg";
import GREEN from "../assets/partnersLogo/Greenkawn-new.svg";
import HiSOVA from "../assets/partnersLogo/Sova-new.svg";
import HONCHOSOFT from "../assets/partnersLogo/HonchoSoft-new.svg";
import HyLyt from "../assets/partnersLogo/Hylyt-new.svg";
import iPRATHANAA from "../assets/partnersLogo/Iprarthana-new.svg";
import IMPACTSURE from "../assets/partnersLogo/ImpactSure-new.svg";
import INFOVIEW from "../assets/partnersLogo/Infoview-new.svg";
import INNOMAINT from "../assets/partnersLogo/Innomaint-new.svg";
import JayamSolutions from "../assets/partnersLogo/Jayam-Solutions.png";
import MADRASMINDWORKS from "../assets/partnersLogo/MadrasmindWorks-new.svg";
import Motadatalogo from "../assets/partnersLogo/Mota-Data-new.svg";
import NETCORE from "../assets/partnersLogo/Netcore-new.svg";
import PINNACLETELESERVICES from "../assets/partnersLogo/Pinnacle-new.svg";
import ScopeX from "../assets/partnersLogo/Scopex-new.svg";
import SHIELDFINALLOGO from "../assets/partnersLogo/Shield-Logo.svg";
import THYNKBLYNK from "../assets/partnersLogo/Thynk-Blynk-new.svg";
import VAKEN from "../assets/partnersLogo/Vaken-new.svg";
import walletHR from "../assets/partnersLogo/wallet-HR-new.svg";
import WINAIM from "../assets/partnersLogo/Winaim.svg";
import tata from "../assets/tata.svg";
import adib from "../assets/adib.svg";
import adcb from "../assets/adcb.svg";
import nbd from "../assets/nbd.svg";
import fab from "../assets/fab.svg";
import fd from "../assets/fd.svg";
import fe from "../assets/fe.svg";
import img from "../assets/img.svg";
import emaar from "../assets/emaar.jpg";
import masheq from "../assets/masheq.jpg";
import nationalcancer from "../assets/nationalcancer.jpg";
import qinvest from "../assets/qinvest.jpg";
import rakbank from "../assets/rakbank.jpg";
import scendumlogo from "../assets/scendumlogo.jpg";
import ucla from "../assets/ucla.jpg";
import vfs from "../assets/vfs.jpg";
import AWSWE from "../assets/aws.png";
// import Flutter from "../assets/Flutter.svg"
import Flutter from "../assets/flutter.png";
import React from "../assets/react.png";
import Angular from "../assets/angular.png";
import vuejs from "../assets/vuejs.png";
import mangodp from "../assets/mongodb.png";
import Net from "../assets/Net.png";
import Grails from "../assets/grailsgroovy.png";
import elixir from "../assets/elixir.png";
import Go from "../assets/go.png";
import spring from "../assets/spring.png";
import java from "../assets/java.png";
import oracle from "../assets/oracle.png";
import android from "../assets/android.png";
import python from "../assets/python.png";
import node from "../assets/node.png";
import hadoop from "../assets/hadoop.png";
import devops from "../assets/devops.png";
import csharp from "../assets/csharp.png";
import spark from "../assets/spark.png";
import webreathelogo from "../assets/webreathelogo.png";
import postgre from "../assets/postgre.png";
import microsoft from "../assets/microsoft.png";
import sqlserver from "../assets/sqlserver.png";
import cloud from "../assets/cloud.png";
import image1 from "../assets/LandingPageBG1.png";
import image2 from "../assets/LandingPageBG2.png";
import image3 from "../assets/LandingPageBG3.png";
import SOVA_logo from "../assets/industrialPartnersLogo/SOVA_logo.jpeg";

// 18/1/25 added
import wipro3d_logo from "../assets/industrialPartnersLogo/wipro3d_logo.jpeg";
import esds_logo from "../assets/industrialPartnersLogo/esds_logo.jpeg";
import ROCKWELL_AUTOMATION from "../assets/industrialPartnersLogo/ROCKWELL_AUTOMATION.jpg";
import BTG from "../assets/industrialPartnersLogo/BTG.png";
import THEDAL_LOGO from "../assets/industrialPartnersLogo/THEDAL_LOGO.jpeg";
import Everseai_logo from "../assets/industrialPartnersLogo/Everseai_logo.jpeg";
import Tekshei_logo from "../assets/industrialPartnersLogo/Tekshei_logo.jpg";

// skill enablers
import STOCKATHON_LOGO from "../assets/skillEnablersLogo/STOCKATHON_LOGO.jpg";
import HARITHA_INSTITUTE from "../assets/skillEnablersLogo/HARITHA_INSTITUTE.jpeg";
import ASSR_EMPHORIUM_LOGO from "../assets/skillEnablersLogo/ASSR_EMPHORIUM_LOGO.jpeg";
import Eduvitaeservices_logo from "../assets/skillEnablersLogo/Eduvitaeservices_logo.jpeg";
import MPower_Aditya_Birla_logo from "../assets/skillEnablersLogo/MPower_Aditya_Birla_logo.jpeg";
import edtodo from "../assets/skillEnablersLogo/edtodo.jpeg";
import COGNioT_LOGO from "../assets/skillEnablersLogo/COGNioT_LOGO.jpg";
import KARAM_ACADEMY from "../assets/skillEnablersLogo/KARAM_ACADEMY.jpeg";
import SENTERSOFT_LOGO from "../assets/skillEnablersLogo/SENTERSOFT_LOGO.jpeg";
import mtutor from "../assets/skillEnablersLogo/mtutor.jpg";

// strategicPartners
import naan_muthalvan from "../assets/strategicPartnersLogo/naan_muthalvan.png";
import tnsdc_logo from "../assets/strategicPartnersLogo/tnsdc_logo.png";
import tnskills from "../assets/strategicPartnersLogo/tnskills.png";
import iTNT_logo from "../assets/strategicPartnersLogo/iTNT_logo.png";
import Tn_auto_skills from "../assets/strategicPartnersLogo/Tn_auto_skills.png";
import startuptn from "../assets/strategicPartnersLogo/startuptn.png";
import keralastartuplogo from "../assets/strategicPartnersLogo/keralastartuplogo.svg";
import KSTAlogo from "../assets/strategicPartnersLogo/KSTAlogo.png";
import nsdclogo from "../assets/strategicPartnersLogo/nsdclogo.jpg";

//proud members
import mma_logo from "../assets/proudMembersLogo/mma_logo.png";
import isharelogo from "../assets/proudMembersLogo/isharelogo.png";
import SICCI from "../assets/proudMembersLogo/SICCI.jpeg";
import cii4 from "../assets/proudMembersLogo/cii4.png";
import LUB_LOGO from "../assets/proudMembersLogo/LUB_LOGO.jpeg";

import Learn_develop from "../assets/learn_develop2.jpg";
import shield_learning from "../assets/shield_learning.png";

import shield_hover_logo from "../assets/shield_hover_logo.png";

import white_texture_bg from "../assets/white_texture_bg.avif";
import white_texture_bg2 from "../assets/white_texture_bg2.avif";

const corouselData = [
  {
    id: 1,
    title: "CONNECT, COLLABORATE, CELEBRATE",
    subTitle: "Our Promise to our Clients",
    img: image1,
  },
  {
    id: 2,
    title: "We Help Organizations",
    subTitle: "Leverage Intelligent Automation",
    img: image2,
  },
  {
    id: 3,
    title: "Our focus is to be Leading",
    subTitle: "in Digital Transformation",
    img: image3,
  },
];

const forteData = [
  {
    id: 1,
    name: "System Integration",
    img: systemIntegration,
    content: `Business technology has made significant progress and now includes systems that are used across entire companies and cover various aspects of the business. This progress has been driven by the combination of digital platforms, which have become more flexible and scalable, allowing for real-time processing and integration across multiple channels. These advancements have contributed to the growth of the global business sector. Creating these advanced systems involves tasks like designing and building custom structures or applications, connecting them with new or existing hardware and software, and establishing communication networks. We at Atribs, provide support for managing programs, designing, creating architectures, integrating systems, implementing solutions, and providing ongoing support. We also work with complex scenarios and specific categories of information to give our clients an extra advantage.`,
  },
  {
    id: 2,
    name: "Digital Pods",
    img: digital,
    content: `Digital Pods offer a distinct departure from conventional team-building models. These self-contained functional units possess the ability to assume complete responsibility for product functionality. They encompass a diverse range of skills essential for the formation of agile teams dedicated to mobile, data, and web products. Unlike regular project teams, Pods operate under a broader mandate, emphasising the need to surpass conventional thinking and foster innovation.`,
  },
  {
    id: 3,
    name: "Application Development",
    img: appLogo,
    content: `Our team of developers works hard to create the best solutions for our clients. We pay attention to both the big picture and small details. We use the latest technology and design techniques to make sure our applications are secure, visually appealing, easy to use, and can grow as needed. These things are very important to us.`,
  },
  {
    id: 4,
    name: "Shield Skill Hub",
    img: shield_learning,
    hoverImage: shield_hover_logo,
    //     content: `At ATRIBS Global, we believe in nurturing talent and fostering continuous growth. With this commitment, Shield Skill Hub serves as the dedicated training arm of ATRIBS Global, driving skill development and professional excellence.Shield Skill Hub is more than just a training platform; it is a skill enabler designed to bridge the gap between industry demands and workforce capabilities. Our programs focus on empowering individuals and organizations with cutting-edge skills in emerging technologies, software development, and professional excellence.
    // Join us at Shield Skill Hub and take the next step toward a brighter, more successful future.
    // `,
    content: `
 <p>
  At ATRIBS Global, we are dedicated to nurturing talent and fostering continuous growth through <b>Shield Skill Hub</b>, our training arm that drives skill development and professional excellence.
</p>
<p>
  <b>Shield Skill Hub</b> bridges the gap between industry demands and workforce capabilities, empowering individuals and organizations with cutting-edge skills in emerging technologies and more.
</p>
<p>
  Take the next step toward a brighter future. Visit <b><a href="https://www.shieldskillhub.com" target="_blank" style="color: inherit; text-decoration: none;">www.shieldskillhub.com</a></b> to learn more!
</p>
`,
  },

  // {
  //   id: 4,
  //   name: "Professional Services",
  //   img: professional,
  //   content: `We have been helping companies find the right people for their job openings for more than ten years. Our main focus has always been connecting clients with the best candidates available in the market. We have a team that can quickly find the right resources for our clients, which has been a key factor in our success. Our clients and the people we have hired can attest to our growth and progress over time.`,

  //   para: `Here are the services we offer:`,

  //   list: `<li><span>IT Consulting: </span>We provide solutions for hiring IT professionals on a permanent basis, hiring them temporarily with the option to hire them permanently later, and hiring IT professionals from within the same country.</li>`,
  //   list1: `<li><span>Quality Consulting: </span>We have teams of experts in various industries who can provide consulting services to improve quality standards.</li>`,
  //   list2: `<li><span>Enterprise Solutions: </span>We have a strong track record of delivering successful solutions on Oracle and SAP platforms through our group companies and partnerships.</li>`,
  // },
];

const clientReadmoreData = [
  {
    title: "Connect, Collaborate <div><p>and Celebrate</p></div>",
    img: connected,
    content: `<div><p>"Connect, collaborate, and celebrate" form the foundation for our clients serving as guiding principles that shape how we engage with them to achieve shared success.</p>

    <p>The concept of connecting embodies the importance of understanding our clients' needs, goals, and challenges, enabling us to tailor our solutions accordingly. By forging strong connections, we can effectively address their unique requirements and provide targeted assistance.</p> 
    
    <p>Collaboration lies at the heart of our approach, emphasising the value of working alongside our clients to attain their objectives. Whether it involves joint project development and execution or utilising our expertise to deliver customised solutions, we believe in fostering collaborative relationships that drive mutual growth.</p>
    
    <p>Moreover, we prioritise celebrating our clients' accomplishments and milestones. This entails recognising their successes and appreciating their achievements, ensuring that we actively support and celebrate the progress they make. By doing so, we cultivate an environment that values our clients and nurtures a culture of shared triumph.</p>
    
    <p>These three principles serve as the mantra of our client-centric, collaborative, and success-oriented culture at ATRIBS. Through our commitment to connecting, collaborating, and celebrating with our clients, we build enduring relationships, achieve superior outcomes, and ultimately realise mutual success.</p>
    </div>`,
  },
  {
    title: "Leverage Intelligent automation",
    img: leverage,
    // content:
    //   `Intelligent automation is the use of technologies such as artificial intelligence (AI), machine learning (ML), and robotic process automation (RPA) to automate and optimize business processes. Leveraging intelligent automation means using these technologies to improve efficiency, productivity, and accuracy in various tasks and operations.There are several ways to leverage intelligent automation in a business setting. For example:Automating repetitive tasks: RPA can automate routine tasks like data entry or invoice processing. This frees employees to focus on more complex tasks requiring human judgment and creativity.Improving decision-making: Machine learning algorithms can analyze large amounts of data to identify patterns and make predictions, which can help businesses make more informed decisions.Enhancing customer experience: Chatbots and other AI-powered tools can provide 24/7 customer support, quickly answering frequently asked questions and resolving issues in real-time.Streamlining operations: Intelligent automation can help businesses optimize their workflows and reduce errors, improving efficiency and cost savings.Overall, leveraging intelligent automation can help businesses stay competitive in an increasingly digital world by enabling them to work smarter, faster, and more accurately.`,
    content: `
      <div><p>Intelligent automation is the use of advanced technologies like artificial intelligence, machine learning, and robotic process automation to automate and improve business processes. It helps businesses become more efficient, productive, and accurate in their tasks and operations. Here are a few examples of how intelligent automation can be used:</p> 
      <p><span>Automating repetitive tasks:</span> Robotic process automation can take care of mundane tasks like data entry or invoice processing. This allows employees to focus on more complex tasks that require human judgment and creativity.</p>
      <p><span>Improving decision-making: </span>Machine learning algorithms can analyze large amounts of data, identify patterns, and make predictions. This helps businesses make better-informed decisions.</p>
      <p><span>Enhancing customer experience: </span>AI-powered tools like chatbots can provide round-the-clock customer support, quickly answering common questions and resolving issues in real-time.</p>
      <p><span>Streamlining operations: </span>Intelligent automation helps optimize workflows and reduce errors, leading to improved efficiency and cost savings.</p>
      <p>By leveraging intelligent automation, businesses can stay competitive in the digital age by working smarter, faster, and more accurately.</p></div>`,
  },
  {
    title: "Data Journey",
    img: DataManagement,
    content: `<div><p>ATRIBS aims to excel in its digital transformation journey by focusing on key areas related to data:</p>

    <p><span>Data Strategy: </span>A clear plan is essential for ATRIBS to navigate its data journey. This involves setting specific data goals, defining key metrics, and creating a roadmap to achieve these objectives.</p>
    
    <p><span>Data Quality: </span>Reliable and accurate data is crucial for successful digital transformation. ATRIBS prioritizes maintaining data accuracy, completeness, and consistency. It invests in data quality processes, tools, and establishes data governance policies to ensure data integrity.</p>
    
    <p><span>Analytics: </span>Extracting valuable insights from data is a key aspect of ATRIBS' digital transformation. It invests in analytics tools and technologies to analyse data and generate actionable insights. This includes developing data models, visualisations, and reports that help stakeholders understand the data better.</p>
    
    <p><span>Data Culture: </span>A data-driven culture is vital for ATRIBS' digital transformation success. It fosters an environment where data is valued, and employees are encouraged to use data for decision-making. ATRIBS provides training and development opportunities to enhance data literacy among its employees.</p>
    
    <p><span>Data Security: </span>Protecting data from theft, loss, or corruption is of utmost importance during the data journey. ATRIBS implements robust security measures such as encryption, access controls, and data backups. It also develops security policies and procedures to safeguard data effectively.</p>
    
    <p>By focusing on these key areas, ATRIBS aims to become a leading performer in its digital transformation journey, leveraging data to make informed decisions and achieve better business outcomes.</p></div>`,
  },
];

const aboutDetails = [
  {
    id: 1,
    name: "ABOUT US",
    content: `At our core, we strive to be the forefront of the Information Technology industry, setting the standard as a market leader. We believe in the power of collaboration, working hand in hand with our clients to achieve shared success. By fostering a culture of empowerment, we inspire our employees to push boundaries, explore new ideas, and innovate in their roles.`,
    img: about,
  },
  {
    id: 2,
    name: "OUR MISSION",
    content:
      "To be a market leader in Information technology industry through collaborative approach with the clients while empowering our employees to explore and innovate in their roles for the present and the future.",
    img: mission,
  },
  {
    id: 3,
    name: "OUR TEAM",
    content: `Our Leadership team comprises of seasoned professionals across Banking & Finance , Software Development ,Human Resources Management, ERP, Quality Assurance, Business Process Consulting and other facets of management.
We take pride in having associates across 12 nationalities adding to our workforce diversity & culture for an enriching experience.`,
    img: team,
  },
  {
    id: 4,
    name: "LEARN-DEVELOP",
    content: `At ATRIBS Global, we are committed to nurturing talent and fostering growth. Our dedicated training arm, Shield Skill Hub <b><a href="https://www.shieldskillhub.com" target="_blank" style="color: inherit; text-decoration: none;">www.shieldskillhub.com</a></b>, bridges the gap between industry demands and workforce capabilities.
Shield Skill Hub empowers individuals and organizations with advanced skills in emerging technologies, software development, and professional excellence.
Join us at Shield Skill Hub to elevate your skills and achieve a brighter, successful future.`,
    img: Learn_develop,
  },
];
const headlines = [
  {
    head: "The first carousel to be",
    title: "Connect, Collaborate, Celebrate",
    subtitle: "Our Promise to our Clients",
    img: connected,
  },
  {
    title: "We Help Organizations",
    subtitle: "Leverage Intelligent Automation ",
    img: insideimg,
  },
  {
    title: "Our focus is to be leading",
    subtitle: "In Digital Transformation",
    img: DataManagement,
  },
];
const solutionDetailData = [
  {
    title: "Unified Digital Workspace",
    content:
      "A Unified Digital Workspace offers cohesive digital workspaces equipped with diverse technologies, tools, and applications, ensuring secure management of information. It provides a user-friendly experience as applications and data are accessible on any device, from anywhere. This unified workspace guarantees easy and secure access to confidential data, enhancing productivity and employee satisfaction. Key features include:",
    content1: [
      "<p><span>Multi-Layer Security: </span>Robust authentication measures ensure information security.</p>",
    ],
    content2: [
      "<p><span>Information Protection: </span>Prevents unauthorized re-sharing and forwarding of data.</p>",
    ],
    content3: [
      "<p><span>Seamless Knowledge Transfer: </span>Facilitates smooth transfer of knowledge from old to new users.</p>",
    ],
    content4: [
      "<p><span>24/7 Data Security: </span>Ensures continuous data protection.</p>",
    ],
    content5: [
      "<p><span>Multi-Filter Access: </span>Enables accessing information through various filters.</p>",
    ],
    content6: [
      "<p><span>One-Click content Saving: </span>Allows saving content from any app or screen with a single click.</p>",
    ],
    content7: [
      "<p><span>Tag-based File Linking: </span>Links files from different locations using tags, enabling simultaneous access.</p>",
    ],
    content8: [
      "<p><span>One-Click Sharing: </sapn>Simplifies sharing information across multiple platforms like Facebook, Twitter, LinkedIn, etc. with a single click.</p>",
    ],

    img: Unified,
  },
  {
    title: "Industrial Maintenance Management",
    content:
      "Industrial Maintenance Management Software helps organizations to optimise and manage their industrial units. This software allows organizations to plan, create and track work orders. It also helps in creating preventive maintenance to avoid one-time repairs.",

    content1: [
      " Increasing industrial units worldwide have also created advanced systems to maintain labour tracking, material requirements planning and corrective action request management.",
    ],

    content2:
      "Finally, it aids in providing valuable insights using data analytics like identifying the types of maintenance required, opportunities to save cost and many more.",
    img: IndustialMAintenace,
  },
  {
    title: "Micro Finance/ Co-operative Society IT Solutions",
    title1: "Micro Finance IT Solutions",
    content1: [
      "Managing and carrying out Micro Finance has become a cakewalk with the advent of the innovative, highly automated, flexible and scalable software solution. The software can handle, facilitate and monitor group or individual transactions flexibly. It can be monitored remotely with minimum employee strength and delivers excellent customer service anywhere. Moreover, all the branch activities are conducted smoothly with the software.",
    ],
    title2: "Co-operative Societies IT Solutions",
    content2:
      "The complex processes of Co-operative Societies have been made easy through the Software Solution. The calculations and supervision are accurately done. This software has exhaustive features for Multi-Branch and Multi-State Management. In addition, the system is highly secured and integrated to provide cost-effective, quality and error-free solutions for varied plans.",
    img: MicroFinance,
  },
  {
    title: "Augmented Reality / Virtual Reality / WEB 3.0 through METAVERSE",
    content: [
      "Any industry with a specific problem statement can be provided with VR, AR or Metaverse solutions to overcome the issue. Whether it is a Nuclear plant or a Construction site, VR or AR or MR, Web 3.0 can be used through Metaverse to provide solutions. Such solutions are tested and educated to the customers for safe results.",
    ],
    img: Agumented,
  },
  {
    title: "Trade finance and AI ML NLP and Document Extraction and Analysis",
    content:
      "Make quick, insightful decisions based on clearly scrutinized documents via a paperless interface ",
    content1: [
      "Scritinize and analyze complex documents with accurancy and confidence to provide precise GrAnalytics.Reduce the time taken to process documentation from hours to MdSettingsInputSvideo.",
    ],
    content2: "This clearly gives an edge over the competition.",
    img: TradeFinance,
  },
  {
    title: "Agri Lending",
    content1:
      "The digital companion of the farmer offers a convenient platform for obtaining effortless and beneficial loans from the comfort of their homes. These loans can be utilized for various purposes such as acquiring seeds, livestock, equipment, expanding businesses, or any other farming needs. Undoubtedly, it serves as a hidden blessing for the farming community, who endure numerous challenges to ensure a steady supply of food on our tables.",
    img: AgriLending,
  },
  {
    title: "Blockchain",
    content:
      "Blockchain is a distributed ledger technology that uses cryptography to secure and validate transactions on a network of computers. It is the technology behind cryptocurrencies like Bitcoin, but it can also be used for other purposes, such as supply chain management and voting systems. In a blockchain, transactions are grouped into blocks, and each block is linked to the one before it, creating a chain of blocks. This makes the data stored on a blockchain secure and resistant to tampering. This is what is being used to create empowered people and business relationships.",
    img: Blackchain,
  },
  {
    title: "AI-Based Cybersecurity",
    content:
      "AI-based cybersecurity refers to using artificial intelligence techniques to improve the detection and prevention of cyber threats. AI-based systems automate repetitive security tasks such as monitoring network traffic and identifying anomalies. Additionally, AI improves incident response and remediation by providing actionable insights to security teams.",
    img: AllBased,
  },
  {
    title: "Cattle Management and Tracking",
    content: [
      "AI-based cattle management and tracking refers to the use of artificial intelligence techniques to better monitor and manage cattle. This involves analysing data from various sources like sensors, RFID tags, and GPS trackers using machine learning algorithms. By doing so, patterns can be identified and predictions can be made about the health and condition of the animals.",
    ],

    content1: [
      "For instance, AI-based systems can recognise signs of sickness in cattle, estimate the optimal time for breeding or slaughter, and optimise schedules for feeding and watering. These systems are created to assist farmers in making informed decisions about their operations and enhance the overall well-being of their cattle.",
    ],
    img: Cattle,
  },
  {
    title: "Conversational AI BOTS",
    content:
      "A Conversational AI platform is designed to assist businesses by offering chatbot integration with WhatsApp. By seamlessly integrating chatbots with WhatsApp, businesses can provide customers with a smooth conversational experience. These AI-driven chatbots enable automation of various aspects such as customer service, marketing, sales, and other use cases on WhatsApp, ultimately helping businesses achieve success in a shorter timeframe.",
    img: WhatsappBot,
  },
  {
    title: "ITOPS & ITSM",
    content:
      "ITOP refers to the processes, systems, and tools used to manage and support the day-to-day operations of an IT environment. ITOP is concerned with ensuring IT services' stability, reliability, and performance.ITSM, on the other hand, is a set of practices and procedures used to manage and deliver IT services to customers effectively and efficiently. ITSM focuses on customer needs and aligning IT services with business goals In summary, ITOP focuses on the technical side of IT operations, while ITSM focuses on delivering and managing IT services from a customer-centric perspective.",
    img: Itops,
  },
  {
    title: "AWS Cloud Managementt",
    content:
      "AWS cloud management environments refer to deployment models in which the cloud provider (Amazon Web Services) manages the infrastructure, allowing the user only to manage their code and its execution. In a serverless environment, the user pays only for the resources used during the actual execution of their code, eliminating the need to provision and manage infrastructure.",
    img: ReadSoti,
  },
  {
    title: "Maintenance Management",
    content:
      "Maintenance Management Solution is a software tool designed to help organizations manage and optimize their maintenance operations. These solutions provide asset management, work order management, preventive maintenance, inventory management, and more to help organizations streamline their maintenance processes and improve overall efficiency. Maintenance management solutions can help organizations reduce downtime, improve equipment reliability, and ensure compliance with industry standards by automating many manual processes and providing real-time visibility into maintenance operations.",
    img: ReadMaintainace,
  },

  {
    title: "Energy Controls and Automation",
    content:
      "Energy controls and automation refers to using technology to manage, monitor and control energy use in buildings, factories, and other facilities. This can include HVAC, lighting, energy generation and storage automation systems. The goal is to optimize energy use, reduce waste, and increase efficiency. This can be achieved through a combination of sensors, controls, and software that can be monitored and adjusted remotely.",
    img: ReadEnergy,
  },
  {
    title: "Cloud Automation Digital Catalogue",
    content:
      "The Cloud Automation Digital Catalogue is a platform that automates the management and deployment of cloud services. It provides an overview of available cloud services and enables users to select and deploy them to meet their business needs easily. The catalogue integrates with cloud providers to simplify the deployment process and reduce manual effort, making it easier for businesses to take advantage of the benefits of the cloud. The platform also features customizable templates and a central control panel, allowing users to manage and monitor their cloud deployments from a single location.",
    img: CloudAutomation,
  },

  {
    title: "HRMS",
    content:
      "HRMS (Human Resource Management System) is a software solution designed to help organizations manage their human resources operations. It provides various HR tools and services, including employee management, benefits administration, payroll management, time and attendance tracking, and more. An HRMS aims to streamline HR operations, improve efficiency, and provide HR teams with the information and tools they need to effectively manage their organization's workforce. A modern HRMS can also provide real-time analytics and reporting, helping HR teams make data-driven decisions and stay on top of key HR metrics.",
    img: ReadHRMS2,
  },
  {
    title: "LOW CODE NO CODE PLATFORM",
    content:
      "A business solution that enables users to build, deploy, and manage enterprise-level applications without writing code. The focus is on making it easier for businesses to automate and streamline their processes through pre-built templates and drag-and-drop interfaces. The goal is to reduce the need for technical expertise, increase productivity, and accelerate the development of business solutions",
    img: ReadLocode,
  },
];
const solutionData = [
  {
    id: 0,
    title: "Unified Digital Workspace",
    content: `A Unified Digital Workspace offers cohesive digital workspaces equipped with diverse technologies,`,
    img: Uni,
  },
  {
    id: 1,
    title: "Industrial Maintenance Management",
    content:
      "An Automated way of Tracking Assets, Managing Work Orders and Scheduling Preventive Maintenance.",
    img: Industrial,
  },
  {
    id: 2,
    title: "Micro Finance/ Co-operative Society IT Solutions",
    content:
      "Empowering Micro Finance Institutions and facilitating Co-operative Societies through software and Cloud Computing",
    img: Micro,
  },
  {
    id: 3,
    title: "Augmented Reality / Virtual Reality / WEB 3.0 through METAVERSE",
    content:
      "Providing intelligent solutions across industries through VR, AR, MR and Web 3.0",
    img: Augmented,
  },
  {
    id: 4,
    title: "Trade finance and AI ML NLP and Document Extraction and Analysis",
    content:
      "Make quick, insightful decisions based on clearly scrutinized documents via a paperless interface",
    img: Trade,
  },
  {
    id: 5,
    title: "Agri Lending",
    content:
      "The digital companion of the farmer offers a convenient platform for obtaining effortless and beneficial loans from the comfort of their homes. These loans can be utilized for various ",
    img: Agri,
  },
  {
    id: 6,
    title: "Blockchain",
    content:
      "Building trust in a digital world: secure, transparent, immutable.",
    img: blockchain,
  },
  {
    id: 7,
    title: "AI-Based Cybersecurity",
    content: "Securing the future with AI technology",
    img: cyber,
  },
  {
    id: 8,
    title: "Cattle Management and Tracking",
    content:
      "AI-based cattle management and tracking refers to the use of artificial intelligence techniques to better monitor and manage cattle. This involves analysing data from various sources like ",
    img: cattle,
  },
  {
    id: 9,
    title: "Conversational AI BOTS",
    content:
      "A Conversational AI platform is designed to assist businesses by offering chatbot integration with WhatsApp.",
    img: bot,
  },
  {
    id: 10,
    title: "ITOPS & ITSM",
    content:
      "Optimizing IT Operations and Delivering Exceptional Service, Every Time.",
    img: itops,
  },
  {
    id: 11,
    title: "AWS Cloud Managementt",
    content: "Run your infrastructure your way in the cloud",
    img: AWS,
  },
  {
    id: 12,
    title: "Maintenance Management",
    content: "Transform maintenance operations with smart technology",
    img: maintenance,
  },

  {
    id: 13,
    title: "Energy Controls and Automation",
    content: "Empowering Your Business Through Innovative Energy Solutions",
    img: EnergyControl,
  },
  {
    id: 14,
    title: "Cloud Automation Digital Catalogue",
    content: "Streamline your cloud journey with ease",
    img: cloud,
  },

  {
    id: 15,
    title: "HRMS",
    content: "Streamline HR operations and simplify work with a modern HRMS",
    img: HRMS2,
  },
  {
    id: 16,
    title: "LOW CODE NO CODE PLATFORM",
    content:
      "A business solution that enables users to build, deploy, and manage enterprise-level applications without writing code. ",
    img: Locode,
  },
];
const atribsDetail = [
  { id: 0, title: "A", img: ACLogo, isShown: false },
  { id: 1, title: "T", img: TCLogo, isShown: false },
  { id: 2, title: "R", img: RCLogo, isShown: false },
  { id: 3, title: "I", img: ICLogo, isShown: false },
  { id: 4, title: "B", img: BCLogo, isShown: false },
  { id: 5, title: "S", img: SCLogo, isShown: false },
];

const mapLocations = [
  { id: 0, top: "56%", left: "67.5%" },
  { id: 1, top: "58%", left: "68.5%" },
  { id: 2, top: "40.5%", left: "85%" },
  { id: 3, top: "55%", left: "76%" },
  { id: 4, top: "62%", left: "75%" },
  { id: 5, top: "59%", left: "66%" },
  { id: 6, top: "42%", left: "21%" },
];

// const splitImagesIntoChunks = (images, chunkSize) => {
//   let result = [];
//   for (let i = 0; i < images.length; i += chunkSize) {
//     result.push(images.slice(i, i + chunkSize));
//   }
//   console.log("Split images into chunks:", result); // Debugging output
//   return result;
// };

// const combinedData = [
//   {
//     title: "",
//     images: splitImagesIntoChunks(
//       [
//         Aarc,
//         CMA,
//         Darktarace,
//         GREEN,
//         HyLyt,
//         INFOVIEW,
//         INNOMAINT,
//         JayamSolutions,
//         MADRASMINDWORKS,
//         Motadatalogo,
//         SHIELDFINALLOGO,
//         THYNKBLYNK,
//         VAKEN,
//         WINAIM,
//         SOVA_logo,
//       ],
//       8
//     ), // Split into chunks of 8
//   },
//   {
//     title: "Industrial Partners",
//     images: [
//       wipro3d_logo,
//       esds_logo,
//       ROCKWELL_AUTOMATION,
//       BTG,
//       THEDAL_LOGO,
//       Everseai_logo,
//       Tekshei_logo,
//       SOVA_logo,
//     ],
//   },
//   {
//     title: "Strategic Partners",
//     images: [
//       naan_muthalvan,
//       tnsdc_logo,
//       tnskills,
//       iTNT_logo,
//       Tn_auto_skills,
//       startuptn,
//       keralastartuplogo,
//       KSTAlogo,
//       nsdclogo,
//     ],
//   },
// ];

const combinedData = [
  {
    title: "Partners",
    images: [
      Aarc,
      CMA,
      Darktarace,
      GREEN,
      HyLyt,
      INFOVIEW,
      INNOMAINT,
      JayamSolutions,
      MADRASMINDWORKS,
      Motadatalogo,
      SHIELDFINALLOGO,
      THYNKBLYNK,
      VAKEN,
      WINAIM,
      SOVA_logo,
    ],
  },
  {
    title: "Industrial Partners",
    images: [
      wipro3d_logo,
      esds_logo,
      ROCKWELL_AUTOMATION,
      BTG,
      THEDAL_LOGO,
      Everseai_logo,
      Tekshei_logo,
      SOVA_logo,
    ],
  },
  {
    title: "Strategic Partners",
    images: [
      naan_muthalvan,
      tnsdc_logo,
      tnskills,
      iTNT_logo,
      Tn_auto_skills,
      startuptn,
      keralastartuplogo,
      KSTAlogo,
      nsdclogo,
    ],
  },
];

const partnersData = [
  // FIIX,
  // Crud,
  // DigitalXC,
  // Aisensy,
  // HiSOVA,
  // GOODMEETINGS,
  // iPRATHANAA,
  // NETCORE,
  // HONCHOSOFT,
  // ScopeX,
  // PINNACLETELESERVICES,
  // walletHR,
  // Aarc,
  // CMA,
  // Darktarace,
  // GREEN,
  // HyLyt,
  // INFOVIEW,
  // INNOMAINT,
  // JayamSolutions,
  // MADRASMINDWORKS,
  // Motadatalogo,
  // SHIELDFINALLOGO,
  // THYNKBLYNK,
  // VAKEN,
  // WINAIM,
  // wipro3d_logo,
  // esds_logo,
  // ROCKWELL_AUTOMATION,
  // BTG,
  // THEDAL_LOGO,
  // Everseai_logo,
  // Tekshei_logo,
  // SOVA_logo,
  { name: "aarc", logo: Aarc },
  { name: "CMA", logo: CMA },
  { name: "DARK TRACE", logo: Darktarace },
  { name: "GREEN KAWN", logo: GREEN },
  { name: "HyLyt", logo: HyLyt },
  { name: "INFOVIEW", logo: INFOVIEW },
  { name: "INNOMAINT", logo: INNOMAINT },
  { name: "Jayam Solutions", logo: JayamSolutions },
  { name: "Madras Mindworks", logo: MADRASMINDWORKS },
  { name: "Mota Data", logo: Motadatalogo },
  { name: "SHIELD GROUP", logo: SHIELDFINALLOGO },
  { name: "ThynkBlynk", logo: THYNKBLYNK },
  { name: "Vaken", logo: VAKEN },
  { name: "WinAim", logo: WINAIM },
  { name: "Wipro 3D", logo: wipro3d_logo },
  { name: "ESDS", logo: esds_logo },
  { name: "Rockwell Automation", logo: ROCKWELL_AUTOMATION },
  { name: "BTG", logo: BTG },
  { name: "THEDAL", logo: THEDAL_LOGO },
  { name: "eVerse.AI", logo: Everseai_logo },
  { name: "Tekshei", logo: Tekshei_logo },
  { name: "SOVA", logo: SOVA_logo },
];

const industrialPartnerData = [
  wipro3d_logo,
  esds_logo,
  ROCKWELL_AUTOMATION,
  BTG,
  THEDAL_LOGO,
  Everseai_logo,
  Tekshei_logo,
  SOVA_logo,
];

const industrialPartnerData2 = [
  { header: "Industrial Partners" },
  {
    images: [
      wipro3d_logo,
      esds_logo,
      ROCKWELL_AUTOMATION,
      BTG,
      THEDAL_LOGO,
      Everseai_logo,
      Tekshei_logo,
      SOVA_logo,
    ],
  },
];

const strategicPartnersData = [
  { name: "Naan Muthalvan", logo: naan_muthalvan },
  { name: "TNSDC", logo: tnsdc_logo },
  { name: "TNSkills", logo: tnskills },
  { name: "iTNT", logo: iTNT_logo },
  { name: "TN Auto Skills", logo: Tn_auto_skills },
  { name: "StartupTN", logo: startuptn },
  { name: "Kerala Startup", logo: keralastartuplogo },
  { name: "KSTA", logo: KSTAlogo },
  { name: "NSDC", logo: nsdclogo },
];

const skillEnablersData = [
  STOCKATHON_LOGO,
  wipro3d_logo,
  HARITHA_INSTITUTE,
  ASSR_EMPHORIUM_LOGO,
  Eduvitaeservices_logo,
  MPower_Aditya_Birla_logo,
  edtodo,
  COGNioT_LOGO,
  KARAM_ACADEMY,
  SENTERSOFT_LOGO,
  mtutor,
];

// const proudMembersData = [mma_logo, isharelogo, SICCI, cii4, LUB_LOGO];
const proudMembersData = [
  { name: "MMA", logo: mma_logo },
  { name: "ISHARE", logo: isharelogo },
  { name: "SICCI", logo: SICCI },
  { name: "CII", logo: cii4 },
  { name: "LUB", logo: LUB_LOGO },
];

const partialData = [
  {
    img: tata,
  },
  {
    img: adib,
  },
  {
    img: adcb,
  },
  {
    img: nbd,
  },
  {
    img: fab,
  },
  {
    img: fd,
  },
  {
    img: fe,
  },
  {
    img: img,
  },
  {
    img: emaar,
  },
  {
    img: masheq,
  },
  {
    img: nationalcancer,
  },
  {
    img: qinvest,
  },
  {
    img: rakbank,
  },
  {
    img: scendumlogo,
  },
  {
    img: ucla,
  },
  {
    img: vfs,
  },
];
export {
  corouselData,
  forteData,
  clientReadmoreData,
  aboutDetails,
  headlines,
  solutionDetailData,
  solutionData,
  atribsDetail,
  mapLocations,
  partnersData,
  partialData,
  industrialPartnerData,
  skillEnablersData,
  strategicPartnersData,
  proudMembersData,
  combinedData,
  industrialPartnerData2,
};

export const webreatheData = [
  {
    img: AWSWE,
  },
  {
    img: Flutter,
  },
  {
    img: React,
  },
  {
    img: Angular,
  },
  {
    img: vuejs,
  },
  {
    img: mangodp,
  },
  {
    img: Net,
  },
  {
    img: Grails,
  },
  {
    img: elixir,
  },
  {
    img: Go,
  },
  {
    img: spring,
  },
  {
    img: java,
  },
  {
    img: oracle,
  },
  {
    img: android,
  },
  {
    img: webreathelogo,
  },
  {
    img: postgre,
  },
  {
    img: microsoft,
  },
  {
    img: sqlserver,
  },
  {
    img: python,
  },
  {
    img: node,
  },
  {
    img: hadoop,
  },
  {
    img: devops,
  },
  {
    img: csharp,
  },
  {
    img: spark,
  },
];

export const ReadaAboutDetails = [
  {
    title: "ABOUT US",
    content: `
    <div><p>

    At our core, we strive to be the forefront of the Information Technology industry, setting the standard as a market leader. We believe in the power of collaboration, working hand in hand with our clients to achieve shared success. By fostering a culture of empowerment, we inspire our employees to push boundaries, explore new ideas, and innovate in their roles.</p>

    <p>With an unwavering commitment to the present and the future, we constantly adapt to the evolving landscape of technology. Our goal is to remain at the cutting edge, providing solutions that meet the ever-changing needs of our clients. By embracing the opportunities presented by the digital age, we position ourselves as a trusted partner, ready to tackle any challenge that comes our way.</p>
    
    <p>By placing value on both our clients and our employees, we create an environment that encourages creativity, expertise, and growth. Through open communication and a shared vision, we build lasting relationships that extend beyond mere transactions. We aim to become a strategic ally for our clients, providing them with the tools, insights, and support they need to thrive in today's dynamic IT landscape.</p>
    
    <p>As we forge ahead, we remain dedicated to upholding our position as a market leader. Through our collaborative approach, we will continue to drive innovation, unlock new possibilities, and shape the future of the Information Technology industry.</p></div>`,
  },
];
